import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import Loading from "../components/Loading";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import UserService from "../services/UserService";
import DataTable from "react-data-table-component";
import {CSVLink} from "react-csv";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-data-grid/lib/styles.css';
import {customStyles} from "../pages/Constants";

export const DailyLaneDataAnalytics = () => {

    const columns = [
        {
            name: 'Equipment',
            selector: row => row.equipment,
            sortable: true,
            cell: row => <div className="top-routes-text">{row.equipment}</div>
        },
        {
            name: 'Drop Date',
            selector: row => row.dropDate,
            sortable: true,
            cell: row => <div className="top-routes-text">{row.dropDate}</div>
        },
        {
            name: 'Total Miles',
            selector: row => row.totalMiles,
            sortable: true,
            cell: row => <div className="top-routes-text">{row.totalMiles}</div>
        },

        {
            name: 'Loads',
            selector: row => row.loads,
            sortable: true,
            cell: row => <div className="top-routes-text">{row.loads}</div>
        },

        {
            id: 'lane',
            name: 'Line Haul Rate',
            selector: row => '$' + financial(row.averagePricePerMile),
            sortable: true,
            cell: row => <div className="top-routes-text">$ {row.averagePricePerMile}</div>
        }
    ];



    const { register, handleSubmit, reset } = useForm();
    const financial = (x) => Number.parseFloat(x).toFixed(2);
    // user state for form
    const [farelanesUser, setFarelanesUser] = useState(null);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [equipmentValue, setEquipmentValue] = useState(  [
        {value: 'apex', label: 'Apex'},
        {value: 'integra', label: 'Integra'},
    ]);
    const [equipmentOptions, setEquipmentOptions] = useState( {value: 'apex', label: 'Apex'});

    function handleEquipmentChange(value) {
        setEquipmentOptions(value);
    };

    async function handleSearch() {
        const token = await getAccessTokenSilently();

        let text = ''
        for (let i = 0; i < equipmentValue.length; i++) {
            text += equipmentValue[i].value + ",";
        }

        setState({
            message: "loading..",
            loading: true,
            data: []
        });

        UserService.laneAnalytics(startDate.getTime(), endDate.getTime(), token).then(
            response => {
                setData(response.data);
                setState({
                    // content: response.data,
                    loading: false,
                    message: 'Success!'
                });

            },
            error => {
                if (error.response && error.response.status === 401) {
                }
            }
        );
    }


    const [data, setData] = useState(new Date());
    const [state, setState] = useState({
        showResult: false,
        apiMessage: 'Tickers are loading ....',
        error: null,

        // startDate: new Date(),
        // endDate: new Date(),
        content: '',
        message: '',

    });

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();
    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            UserService.me(token, user).then(
                response => {
                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.data
                    });
                    setFarelanesUser({ firstName: response.data.auth0GivenName, lastName: response.data.auth0FamilyName })
                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };


    // effect runs on component mount
    useEffect(() => {
        // simulate async api call with set timeout
        callApi().then();
    }, []);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(farelanesUser);
    }, [farelanesUser]);




    function onSubmit(data) {
        // display form data on submit
        return false;
    }
    return (
        <div className="container-fluid">
            <h5 className="">Search - Lane Data Pairs by Date Range</h5>
            <div className="">
                {farelanesUser &&
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="form-row AppFonts">

                            <div className="col-sm-">
                                <label htmlFor="exampleFormControlFile1"><b>Drop Date (Start)</b></label>
                                <DatePicker className="pclass" showIcon value={startDate} selected={startDate} minDate={new Date("02-01-2024")}
                                            onChange={(date) => setStartDate(date)}/>
                            </div>



                            <div className="col-sm-">
                                <label htmlFor="exampleFormControlFile1"><b>Drop Date (End)</b></label>
                                <DatePicker className="pclass" showIcon value={endDate} selected={endDate}
                                            onChange={(date) => setEndDate(date)}/>
                            </div>

                            <div className="col-sm-2">
                                {/*<label htmlFor="exampleFormControlFile1"><b>[General]</b></label>*/}
                                <br/>
                                <button
                                    className="add_lane_dashboard_button lane-data-a-button"
                                    onClick={handleSearch}
                                    disabled={state.loading}
                                >
                                    {state.loading && (
                                        <span className="spinner-grow"></span>
                                    )}
                                    <span>&nbsp;QUERY&nbsp;</span>

                                </button>
&nbsp;&nbsp;&nbsp;&nbsp;
                                {data && data.length > 0 &&
                                    <CSVLink data={data} filename={"equipment-analytics.csv"}><b> <span className="btn-lg btn btn-warning">Export</span></b></CSVLink>
                                }
                                <br/>
                            </div>
                            <hr/>
                        </div>


                    </form>
                }

                <div className="container-fluid">
                    <hr/>

                    <header className="top-routes-text ">
                        {data && data.length > 0 &&
                        <DataTable
                            // dense={true}
                            customStyles={customStyles}
                            striped={true}
                            columns={columns}
                            data={data}
                            // defaultSortFieldId="lane"
                            defaultSortAsc={false}
                            direction="auto"
                            fixedHeaderScrollHeight="300px"
                            pagination
                            responsive
                            subHeaderAlign="right"
                            subHeaderWrap
                        />
                        }
                    </header>
                    </div>


<br/><br/><br/><br/>
            </div>
        </div>
    );
};

export default withAuthenticationRequired(DailyLaneDataAnalytics, {
    onRedirecting: () => <Loading />,
});
