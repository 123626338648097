import React from "react";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {formatValue} from "react-currency-input-field";

export const PriceBlockComponent = (props) => {

    const financial = (x) => Number.parseFloat(x).toFixed(0);

    return (
        <div className="">
            <div className="">
                <div className="lookup-card">
                    <div className="card-body">
                        {props.object.laneStatistics != undefined && props.object.laneStatistics != undefined &&
                            <div className={
                                props.object.laneStatistics != undefined && props.object.laneStatistics != undefined && props.object.laneStatistics.costPerMile.toString() == '0'
                                    ? "special-card"
                                    : "border-dark"
                            }
                            >
                                {(props.object.subscriptionType == 'Gold' || props.object.subscriptionType == 'Platinum') &&

                                    <ReactTooltip id="thirtyDaysAverage" multiline={true}/>
                                }

                                <a className="price-component-tool-tip" data-tooltip-id="thirtyDaysAverage"
                                   data-tooltip-html={
                                       'No.of.stops - low ' + props.object.laneStatistics.lowNumberOfStops + ' high ' + props.object.laneStatistics.highNumberOFStops + '<br/>' +
                                       'Lead time - low' + props.object.laneStatistics.lowLeadTime + ' high ' + props.object.laneStatistics.highLeadTime + '<br/>' +
                                       'Fuel surcharge - low' + props.object.laneStatistics.lowFuelSurcharge + ' high ' + props.object.laneStatistics.highFuelSurcharge + '<br/>' +
                                       'Lumper - low' + props.object.laneStatistics.lowLumper + ' high ' + props.object.laneStatistics.highLumper + '<br/>' +
                                       'Pallet charge - low' + props.object.laneStatistics.lowPalletCharge + ' high ' + props.object.laneStatistics.highPalletCharge + '<br/>' +
                                       'Weight ticket - low' + props.object.laneStatistics.lowWeightTicket + ' high ' + props.object.laneStatistics.highWeightTicket + '<br/>' +
                                       'Interchange - low' + props.object.laneStatistics.lowInterchange + ' high ' + props.object.laneStatistics.highInterchange + '<br/>' +
                                       'Container fee - low' + props.object.laneStatistics.lowContainerFee + ' high ' + props.object.laneStatistics.highContainerFee + '<br/>' +
                                       'Truck order - low' + props.object.laneStatistics.lowTruckOrder + ' high ' + props.object.laneStatistics.highTruckOrder + '<br/>' +
                                       'Unloading fees - low' + props.object.laneStatistics.lowUnloadingFees + ' high ' + props.object.laneStatistics.highUnloadingFees + '<br/>' +
                                       'Detention - low' + props.object.laneStatistics.lowDetention + ' high ' + props.object.laneStatistics.highDetention + '<br/>'
                                   }>

                                    <p className="align-content-center">{props.object.days}</p>


                                    <h6>{formatValue({
                                        value: props.object.laneStatistics.costPerMile.toString(),
                                        intlConfig: {locale: 'en-US', currency: 'USD'},
                                        decimalScale: 2,
                                        suffix: '/mile'
                                    })}</h6>

                                    <h6>{formatValue({
                                        value: props.object.laneStatistics.averageLoadRate.toString(),
                                        intlConfig: {locale: 'en-US', currency: 'USD'},
                                        decimalScale: 0,
                                        suffix: '/load'
                                    })}
                                    </h6>
                                </a>
                                <hr/>
                                <small>Average Load
                                    Miles: {financial(props.object.laneStatistics.averageLoadMiles)} miles</small><br/>
                                <small>Avg. dist from req pick
                                    up: {financial(props.object.laneStatistics.averageDistanceFromRequestedPickup)} miles</small><br/>
                                <small>Avg. dist from req drop
                                    off: {financial(props.object.laneStatistics.averageDistanceFromRequestedDrop)} miles</small><br/>
                            </div>}
<br/>
                        <div className="">
                            {(props.object.subscriptionType == 'Gold' || props.object.subscriptionType == 'Platinum') &&
                                <button className="button__rates_list"
                                        onClick={props.object.onClick}>
                                    <span>{props.object.laneStatistics && props.object.laneStatistics.documentCount} reports</span>
                                </button>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};
