import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import FarelanesCOPILOTLogo from "../../../assets/images/FL-CO-PILOT-logo.png"
import {useAuth0} from "@auth0/auth0-react";
import UserService from "../../../services/UserService";
import Ticker from "../../Ticker";

export const MobileNavBarBrand = ({handleClick}) => {

    const [subscriptionPlan, setSubscriptionPlan] = useState("");


    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        logout,
    } = useAuth0();


    useEffect(() => {
        // 👇️ This only runs once
        console.log('useEffect ran');

        // 👇️ fetch data from remote API
        async function getUsers() {
            try {
                const token = await getAccessTokenSilently();
                UserService.mySubscription(token).then(
                    response => {
                        setSubscriptionPlan(response.data)
                    },
                    error => {
                        console.log('API timeout or session error; Kill session and redirect to login' + error);
                        loginWithRedirect({appState: {returnTo: '/'}});
                    }
                );


            } catch (err) {

            }
        }

        getUsers();
    }, []); // 👈️ Empty dependencies array

    return (
        <div onClick={handleClick} className="mobile-nav-bar__brand">
                <NavLink to="/dashboard">
                    <img
                        className="nav-bar__logo"
                        src={FarelanesCOPILOTLogo}
                        alt="Farelanes logo"

                    />
                </NavLink>
        </div>

    );
};
