import React, {useEffect, useState} from "react";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import UserService from "../services/UserService";
import {Redirect, useNavigate} from "react-router-dom";
export const SubscriptionVerify = (props) => {

    let navigate = useNavigate();
    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        logout,
    } = useAuth0();

    const [state, setState] = useState({
        showResult: false,
        apiFinished: false,
        apiMessage: 'Tickers are loading ....',
        error: null,
    });

    const callApi = async () => {

        navigate("/settings",{state:{refresh:true}});

    };

    useEffect(() => {
        // declare the data fetching function
        callApi();

    }, [callApi]);


    return (
        <>

        </>
    );
};

export default withAuthenticationRequired(SubscriptionVerify, {
    onRedirecting: () => <Loading/>,
});
