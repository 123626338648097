import React from "react";

export const HeroBanner = () => {
  const logo = "https://cdn.auth0.com/blog/developer-hub/react-logo.svg";

  return (
      <div></div>
    // <div className="hero-banner hero-banner--pink-yellow">
    //   {/*<div className="hero-banner__logo">*/}
    //   {/*  /!*<img className="hero-banner__image" src={logo} alt="React logo" />*!/*/}
    //   {/*</div>*/}
    //   {/*<p className="hero-banner__description">*/}
    //   {/*    Farelanes is the industry’s trusted source of lane pricing data with a variety of data and connection formats to meet your needs.*/}
    //   {/*    Our data publishing solutions, include true, real-time Data Feeds from trusted sources both nation-wide and custom geography segments providing the right answer for all organizations from the Fortune 500 to Owner/Operators..*/}
    //   {/*</p>*/}
    //
    // </div>
  );
};
