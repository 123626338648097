import React, {useEffect, useState} from "react";
import {LaneData} from "../components/LaneData";
import {PageLayout} from "../components/page-layout";

// import TwitterLogo from "../assets/images/social/icons8-twitter.gif";
// import CompanyLogo from "../assets/images/farelanes-logo-gold.png";
import RightDashboard from "./RightDashboard";
import UserService from "../services/UserService";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import {isMobile} from 'react-device-detect';
import {PageLayoutMobile} from "../components/page-layout-mobile";
import {CitySearchMobile} from "../components/CitySearchMobile";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {RateTable} from "./RateTable";
import {TopLanes} from "../components/TopLanes";
import SearchTabs from "./SearchTabs";
import {Favorites} from "./Favorites";
import {Insights} from "./Insights";
import FileUploadProcessor from "./lane-data-file-upload";
import Administration from "./Administration";
import Profile from "./Profile";

const Dashboard = () => {

    const [refreshKey, setRefreshKey] = useState(0);

    // Function to reload the layout
    const reloadLayout = () => {
        setRefreshKey((prevKey) => prevKey + 1);
    };

    let navigate = useNavigate();
    const adminUsers = ['andyc@farelanes.com', 'andrew@farelanes.com', 'andrew.cohen@farelanes.com', 'neal.huffman@farelanes.com', 'kapil.reddy@farelanes.com'];

    const handleDataFromChildAB = (data) => {
        setDefaultIndex(0);
        reloadLayout();
        callApi().then(r => {})
    };
    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        logout,
    } = useAuth0();


    let [subscriptionType, setSubscriptionType] = useState("Default");
    let [defaultIndex, setDefaultIndex] = useState(0);


    let [apiLoaded, setApiLoaded] = useState(false);
    const callApi = async () => {
        setApiLoaded(false)
        console.log('DASH TABS CALL');
        console.log('Fetching active sessions')
        const token = await getAccessTokenSilently();

        UserService.verify(user, token).then(
            response => {
                console.log('Subscription type i==s ' + response.data.subscriptionType);
                console.log('Proceeding. Likely break cause 0')

                setSubscriptionType(response.data.subscriptionType);

                console.log('Proceeding. Likely break cause');
                if (response.data.subscriptionType == undefined) {
                    setDefaultIndex(6);
                }

                if (response.data.subscriptionType == 'Default') {
                    setDefaultIndex(6);
                }
                if (response.data.subscriptionType == 'Silver') {
                    console.log('set index 1');
                    console.log('did it render');
                    setDefaultIndex(0);
                }

                if (response.data.subscriptionType == 'Gold') {
                    setDefaultIndex(0);
                }
                if (response.data.subscriptionType == 'CoPilot') {
                    setDefaultIndex(2);
                }

                if (response.data.subscriptionType == 'Platinum') {
                    setDefaultIndex(0);
                }
                console.log('Crossed 1')
                setApiLoaded(true);
                console.log('Crossed 2');
                console.log('Reload clicked/////');
                reloadLayout();
                console.log('Reload clicked/////');

            },
            error => {
                console.log(error);
                console.log('Crossed 2');
            }
        );
    };

    useEffect(() => {
        // declare the data fetching function
        callApi().then(r => console.log('Call Api() finished'));
        reloadLayout();
    }, [defaultIndex]);


    if (isMobile) {
        return (
            <PageLayoutMobile>
                <CitySearchMobile></CitySearchMobile>
            </PageLayoutMobile>
        )
    }

    return (
        <div>
            <PageLayout  key={refreshKey}>
                {apiLoaded && (
                    <div className="">
                        {/*<button onClick={reloadLayout}>Reload PageLayout</button>*/}

                        <div className="row">
                            <div className="col-md-9 v2-dashboard-vertical-divider">
                                <div><LaneData></LaneData></div>
                                <Tabs defaultIndex={defaultIndex}>
                                    <TabList className="profile-root-tabs">
                                        <Tab disabled={subscriptionType === 'Default' || subscriptionType === 'CoPilot'}>Dashboard</Tab>
                                        <Tab>Top Rates</Tab>
                                        <Tab disabled={subscriptionType === 'Default'}>Rates & History</Tab>
                                        <Tab
                                            disabled={subscriptionType === 'Default' || subscriptionType === 'Silver' || subscriptionType === 'CoPilot'}><span
                                            type="button" className="" data-toggle="tooltip"
                                            data-placement="top" title="Gold & Platinum Feature">
                                    Favorite Lanes
                                </span></Tab>
                                        <Tab
                                            disabled={subscriptionType === 'Default' || subscriptionType === 'Silver' || subscriptionType === 'Gold' || subscriptionType === 'CoPilot'}>      <span
                                            type="button" className="" data-toggle="tooltip"
                                            data-placement="top" title="Platinum Feature">
                                    Insights
                                </span></Tab>
                                        <Tab
                                            disabled={subscriptionType === 'Default' || subscriptionType == 'Silver' || subscriptionType === 'CoPilot'}><span
                                            type="button" className="" data-toggle="tooltip"
                                            data-placement="top" title="Gold & Platinum Feature">
                                    RFQ
                                </span></Tab>
                                        <Tab>Setup</Tab>
                                        {isAuthenticated && ((adminUsers.indexOf(user.email) > -1)) && (
                                            <Tab disabled={subscriptionType === 'Default'}>Administration</Tab>
                                        )}
                                    </TabList>
                                    <TabPanel><RateTable></RateTable></TabPanel>
                                    <TabPanel className={"v2-tab-content"}> <TopLanes></TopLanes></TabPanel>
                                    <TabPanel><SearchTabs subscriptionType={subscriptionType}></SearchTabs>
                                    </TabPanel>
                                    <TabPanel><Favorites></Favorites></TabPanel>
                                    <TabPanel><Insights></Insights></TabPanel>
                                    <TabPanel><FileUploadProcessor></FileUploadProcessor></TabPanel>
                                    <TabPanel><Profile onDataChange={handleDataFromChildAB}></Profile></TabPanel>
                                    {isAuthenticated && ((adminUsers.indexOf(user.email) > -1)) && (
                                        <TabPanel><Administration></Administration></TabPanel>
                                    )}
                                </Tabs>
                            </div>

                            <div className="col-md-3">
                                <div><RightDashboard></RightDashboard></div>
                            </div>
                        </div>
                    </div>
                )}
            </PageLayout>
        </div>
    );
}

export default Dashboard;
