import React from "react";
import { NavBarBrand } from "./nav-bar-brand";
import { NavBarButtons } from "./nav-bar-buttons";
import { NavBarTabs } from "./nav-bar-tabs";
import {useAuth0} from "@auth0/auth0-react";
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const NavBar = () => {

    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        logout,
    } = useAuth0();

    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            }
        });

  return (
    <div className="v2-container">

        <div className={"row"}>
            <div className={"col-md-2 "}>
                <NavBarBrand/>
            </div>


            <div className={"col-md-10"}>
                <NavBarTabs/>
            </div>



        </div>
        {/*<NavBarButtons />*/}
    </div>
  );
};
