import React, {Fragment} from "react";
import {SubscriptionsComponent} from "../components/Susbcriptions";

const Subscriptions = () => (
    <Fragment>
        <SubscriptionsComponent></SubscriptionsComponent>
    </Fragment>
);

export default Subscriptions;
