import {useAuth0} from "@auth0/auth0-react";
import React from "react";
import {NavBarTab} from "./nav-bar-tab";
import {DropdownItem, DropdownMenu, DropdownToggle, NavItem, UncontrolledDropdown} from "reactstrap";
import {NavLink as RouterNavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Ticker from "../../Ticker";
import {SignupButtonMobile} from "../../buttons/signup-button-mobile";
import {LoginButtonMobile} from "../../buttons/login-button-mobile";
import {LogoutButtonMobile} from "../../buttons/logout-button-mobile";

export const NavBarTabs = () => {
    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        logoutWithRedirect,
        logout,
    } = useAuth0();


    return (
        <div>
            {!isAuthenticated && (
                <div className="">
                    {!isAuthenticated && (
                        <>
                            {/*<SignupButtonMobile/>*/}
                            {/*<LoginButtonMobile/>*/}
                        </>
                    )}
                    {isAuthenticated && (
                        <>
                            {/*<LogoutButtonMobile/>*/}
                        </>
                    )}
                </div>
            )}

            {isAuthenticated && (
                <>
                    <div>
                        <Ticker ticker={{id: 1}}></Ticker>
                        <Ticker ticker={{id: 2}}></Ticker>
                    </div>
                </>
            )}
        </div>
    );
};
