import {useAuth0} from "@auth0/auth0-react";
import React from "react";
import {MobileNavBarTab} from "./mobile-nav-bar-tab";
import {NavBarTab} from "../desktop/nav-bar-tab";

export const MobileNavBarTabs = ({handleClick}) => {
    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        logout,
    } = useAuth0();

    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            }
        });

    return (
        <div className="mobile-nav-bar__tabs">

            {isAuthenticated && (
                <>
                    <MobileNavBarTab
                        path="/dashboard"
                        label="Dashboard"
                        handleClick={handleClick}
                    />

                    <MobileNavBarTab
                        label="Logout"
                        handleClick={() => logoutWithRedirect()}
                    />
                </>
            )}
        </div>
    );
};
