import React from "react";
import {PageFooterHyperlink} from "./page-footer-hyperlink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DropdownItem} from "reactstrap";
import {useAuth0} from "@auth0/auth0-react";
import {Auth0Component} from "./Auth0Component";
import {MobileNavBarBrand} from "./navigation/mobile/mobile-nav-bar-brand";
import {MobileMenuToggleButton} from "./navigation/mobile/mobile-menu-toggle-button";
import {MobileNavBarTabs} from "./navigation/mobile/mobile-nav-bar-tabs";
import {MobileNavBarButtons} from "./navigation/mobile/mobile-nav-bar-buttons";
import Ticker from "./Ticker";

export const PageFooterMobile = () => {

    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        logout,
    } = useAuth0();


    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            }
        });

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/dashboard",
            },
            authorizationParams: {
                prompt: "login",
                screen_hint: "signup",
            },
        });
    };

    return (
        <>
            <div className="container-fluid fixed-bottom footer-color footer-style">
                <div className="footer">
                    <span
                        className="footer-style-mobile">© Copyright 2023, 2024 Farelanes LLC. All Rights Reserved.</span>
                </div>
            </div>
        </>
    )
};
