import React, {Fragment} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import AdminLaneDataSearch from "../components/AdminLaneDataSearch";
import {CitySearchAdvanced} from "../components/CitySearchAdvanced";
import {CitySearch} from "../components/CitySearch";
import {DailyLaneDataAnalytics} from "../components/DailyLaneDataAnalytics";

const SearchTabs = ({ subscriptionType }
) => (
    <Fragment>
        <Tabs>
            <TabList className="profile-root-tabs">
                <Tab id="one">Rate Search</Tab>
                <Tab disabled={subscriptionType === 'Default' || subscriptionType == 'Silver' || subscriptionType === 'Gold'}>City  (Top Routes)</Tab>
                <Tab disabled={subscriptionType === 'Default' || subscriptionType == 'Silver' || subscriptionType === 'Gold'}>Equipment Analytics</Tab>
            </TabList>
            <TabPanel><CitySearch></CitySearch></TabPanel>
            <TabPanel><CitySearchAdvanced></CitySearchAdvanced></TabPanel>
            <TabPanel><DailyLaneDataAnalytics></DailyLaneDataAnalytics></TabPanel>
        </Tabs>
    </Fragment>
);

export default SearchTabs;
