import React, {Fragment, useEffect, useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Loading from "../components/Loading";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import UserService from "../services/UserService";
import {MongoChart} from "../components/MongoChart";
import 'react-data-grid/lib/styles.css';

export const Insights = () => {
    const {user,getAccessTokenSilently} = useAuth0();

    const [state, setState] = useState({
        showResult: false,
        apiMessage: 'Tickers are loading ....',
        error: null,
        response: undefined
    });

    let userNavigationDecider = {

    };

    const columns = [
        { key: 'id', name: 'ID' },
        { key: 'title', name: 'Title' }
    ];

    const rows = [
        { id: 0, title: 'Example' },
        { id: 1, title: 'Demo' }
    ];

    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            console.log('User being fetched is' + user.sub);
            UserService.userSettingsFlow(token, user).then(
                response => {
                    console.log(response);
                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.data,
                        response: response.data
                    });
                    userNavigationDecider = response.data

                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };


    // effect runs on component mount
    useEffect(() => {
        // simulate async api call with set timeout
        callApi().then();
    }, []);

    return (
        <Fragment>
            {state.showResult && <Tabs defaultIndex={0} onSelect={(index) => console.log(index)}>
                <TabList className="profile-child-tabs">
                    <Tab>Lane Amount by Equipment</Tab>
                    <Tab>Miles by Equipment</Tab>
                    <Tab>Lane Rate by Miles</Tab>
                </TabList>
                <TabPanel>


                    <div className="secondary-tabs-align">
                    <Tabs>
                        <TabList default={true}>
                            <Tab>
                                <p>2020</p>

                            </Tab>
                            <Tab>
                                <p>2021</p>
                            </Tab>
                            <Tab>
                                <p>2022</p>
                            </Tab>
                            <Tab>
                                <p>2023</p>
                            </Tab>
                            <Tab>
                                <p>2024</p>
                            </Tab>
                        </TabList>

                        <TabPanel>
                            <div className="panel-content">
                                <MongoChart height={'600px'} width={'100'} chartId={'65c1372e-8c9d-4842-86c5-69193398b5e7'}/>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="panel-content">
                                <MongoChart height={'600px'} width={'100'} chartId={'65c13879-858f-4c98-8edf-b38f9ab9b4d2'}/>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="panel-content">
                                <MongoChart height={'600px'} width={'100'} chartId={'65c1394b-fe97-49f6-88cb-83b280ccecdc'}/>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="panel-content">
                                <MongoChart height={'600px'} width={'100'} chartId={'65c14264-8c9d-4c6c-84e5-691933a4bbb0'}/>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="panel-content">
                                <MongoChart height={'600px'} width={'100'} chartId={'65c143af-4433-46f3-8add-a7487b4cf24b'}/>
                            </div>
                        </TabPanel>


                    </Tabs>
                    </div>



                </TabPanel>
                <TabPanel>

                    <div className="secondary-tabs-align">
                        <Tabs>
                            <TabList>

                                <Tab>
                                    <p>2022</p>
                                </Tab>
                                <Tab>
                                    <p>2023</p>
                                </Tab>
                                <Tab>
                                    <p>2024</p>
                                </Tab>
                            </TabList>

                            <TabPanel>
                                <div className="panel-content">
                                    <MongoChart height={'600px'} width={'100'}
                                                chartId={'65c2911e-858f-4793-8c4d-b38f9a62f9d6'}/>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="panel-content">
                                    <MongoChart height={'600px'} width={'100'}
                                                chartId={'65c291b2-858f-4f9a-8d76-b38f9a6c45b1'}/>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="panel-content">
                                    <MongoChart height={'600px'} width={'100'}
                                                chartId={'2fbb8fd2-1a2d-4310-ba93-cf6bfdfffaaa'}/>
                                </div>
                            </TabPanel>



                        </Tabs>
                    </div>

                </TabPanel>
                <TabPanel>

                    <div className="secondary-tabs-align">
                        <Tabs>
                            <TabList>


                                <Tab>
                                    <p>2024</p>
                                </Tab>
                            </TabList>

                            <TabPanel>
                                <div className="panel-content">
                                    <MongoChart height={'600px'} width={'100'}
                                                chartId={'2fbb8fd2-1a2d-4310-ba93-cf6bfdfffaaa'}/>
                                </div>
                            </TabPanel>




                        </Tabs>
                    </div>

                </TabPanel>

                {/*End of Tablist*/}
            </Tabs>
            }
        </Fragment>
    );
};

export default withAuthenticationRequired(Insights, {
    onRedirecting: () => <Loading/>,
});
