import React, {Fragment} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import AdminDataSync from "../components/AdminDataSync";
import AdminLaneDataSearch from "../components/AdminLaneDataSearch";
import {DailyLaneDataAnalytics} from "../components/DailyLaneDataAnalytics";
import {GlobalConfig} from "../components/GlobalConfig";

const Administration = () => (
    <Fragment>
        <Tabs default="three">
            <TabList className="profile-child-tabs">
                <Tab id="one">Data Sync</Tab>
                <Tab>Lane Data</Tab>
                <Tab>Global Configuration</Tab>
            </TabList>
            <TabPanel><AdminDataSync></AdminDataSync></TabPanel>
            <TabPanel><AdminLaneDataSearch></AdminLaneDataSearch></TabPanel>
            <TabPanel><GlobalConfig></GlobalConfig></TabPanel>
        </Tabs>
    </Fragment>
);

export default Administration;
