import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Loading from "../components/Loading";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import UserService from "../services/UserService";

export const GlobalConfig = () => {

    const registerOptions = {
        totalRecords: {
            required: "totalRecords is required",
            maxLength: {
                value: 108,
                message: "totalRecords cannot be more than 108 characters"
            }
        },
        startCityRadius: {
            required: "startCityRadius is required",
            maxLength: {
                value: 30,
                message: "startCityRadius cannot be more than 30 characters"
            }
        },
        dropCityRadius: {
            required: "dropCityRadius is required",
            maxLength: {
                value: 50,
                message: "dropCityRadius cannot be more than 50 characters"
            }
        },
        totalRecordsGold: {
            required: "totalRecordsGold is required",
            maxLength: {
                value: 108,
                message: "totalRecordsGold cannot be more than 108 characters"
            }
        },
        startCityRadiusGold: {
            required: "startCityRadiusGold is required",
            maxLength: {
                value: 30,
                message: "startCityRadiusGold cannot be more than 30 characters"
            }
        },
        dropCityRadiusGold: {
            required: "dropCityRadiusGold is required",
            maxLength: {
                value: 50,
                message: "dropCityRadiusGold cannot be more than 50 characters"
            }
        },
        numberOfDays: {
            required: "Number of Days is required",
            maxLength: {
                value: 3,
                message: "Number of Days than 3 characters"
            }
        },
        lanes100: {
            required: "Lanes is required",
            maxLength: {
                value: 30,
                message: "Zip cannot be more than 30 characters"
            }
        },
        color100: {
            required: "Color is required",
            maxLength: {
                value: 100,
                message: "State cannot be more than 50 characters"
            }
        },
        lanes90: {
            required: "Lanes is required",
            maxLength: {
                value: 30,
                message: "Zip cannot be more than 30 characters"
            }
        },
        color90: {
            required: "Color is required",
            maxLength: {
                value: 100,
                message: "State cannot be more than 50 characters"
            }
        },
        lanes80: {
            required: "Lanes is required",
            maxLength: {
                value: 30,
                message: "Zip cannot be more than 30 characters"
            }
        },
        color80: {
            required: "Color is required",
            maxLength: {
                value: 100,
                message: "State cannot be more than 50 characters"
            }
        },
        lanes70: {
            required: "Lanes is required",
            maxLength: {
                value: 30,
                message: "Zip cannot be more than 30 characters"
            }
        },
        color70: {
            required: "Color is required",
            maxLength: {
                value: 100,
                message: "State cannot be more than 50 characters"
            }
        },
        lanes60: {
            required: "Lanes is required",
            maxLength: {
                value: 30,
                message: "Zip cannot be more than 30 characters"
            }
        },
        color60: {
            required: "Color is required",
            maxLength: {
                value: 100,
                message: "State cannot be more than 50 characters"
            }
        },
        lanes50: {
            required: "Lanes is required",
            maxLength: {
                value: 30,
                message: "Zip cannot be more than 30 characters"
            }
        },
        color50: {
            required: "Color is required",
            maxLength: {
                value: 100,
                message: "State cannot be more than 50 characters"
            }
        },
        lanes40: {
            required: "Lanes is required",
            maxLength: {
                value: 30,
                message: "Zip cannot be more than 30 characters"
            }
        },
        color40: {
            required: "Color is required",
            maxLength: {
                value: 100,
                message: "State cannot be more than 50 characters"
            }
        },
        lanes30: {
            required: "Lanes is required",
            maxLength: {
                value: 30,
                message: "Zip cannot be more than 30 characters"
            }
        },
        color30: {
            required: "Color is required",
            maxLength: {
                value: 100,
                message: "State cannot be more than 50 characters"
            }
        },
        lanes20: {
            required: "Lanes is required",
            maxLength: {
                value: 30,
                message: "Zip cannot be more than 30 characters"
            }
        },
        color20: {
            required: "Color is required",
            maxLength: {
                value: 100,
                message: "State cannot be more than 50 characters"
            }
        },
        lanes10: {
            required: "Lanes is required",
            maxLength: {
                value: 30,
                message: "Zip cannot be more than 30 characters"
            }
        },
        color10: {
            required: "Color is required",
            maxLength: {
                value: 100,
                message: "State cannot be more than 50 characters"
            }
        }

    };
// get functions to build form with useForm() hook
    const { register, handleSubmit, reset } = useForm();

    // user state for form
    const [farelanesUser, setFarelanesUser] = useState(null);

    const [state, setState] = useState({
        showResult: false,
        loading: false,
        apiMessage: 'Tickers are loading ....',
        error: null,

    });

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();
    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            UserService.config(token, user).then(
                response => {
                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.data
                    });
                    console.log(response.data)
                    setFarelanesUser({
                        response: response.data,
                        totalRecords: response.data[0].value,
                        startCityRadius: response.data[1].value,
                        dropCityRadius: response.data[2].value,

                        lanes100: response.data[3].value,
                        color100: response.data[4].value,

                        lanes90: response.data[5].value,
                        color90: response.data[6].value,

                        lanes80: response.data[7].value,
                        color80: response.data[8].value,

                        lanes70: response.data[9].value,
                        color70: response.data[10].value,

                        lanes60: response.data[11].value,
                        color60: response.data[12].value,

                        lanes50: response.data[13].value,
                        color50: response.data[14].value,

                        lanes40: response.data[15].value,
                        color40: response.data[16].value,

                        lanes30: response.data[17].value,
                        color30: response.data[18].value,

                        lanes20: response.data[19].value,
                        color20: response.data[20].value,

                        lanes10: response.data[21].value,
                        color10: response.data[22].value,

                        numberOfDays: response.data[23].value,

                        totalRecordsGold: response.data[24].value,
                        startCityRadiusGold: response.data[25].value,
                        dropCityRadiusGold: response.data[26].value,

                        loading: false, })
                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };


    // effect runs on component mount
    useEffect(() => {
        // simulate async api call with set timeout
        callApi().then();
    }, []);

    // effect runs when user state is updated
    useEffect(() => {
        // reset form with user data
        reset(farelanesUser);
    }, [farelanesUser]);




    async function onSubmit(data) {
        // display form data on submit
        const token = await getAccessTokenSilently();
        setState({
            message: "",
            loading: true
        });

        console.log("****");
        console.log(farelanesUser);
        let payload = farelanesUser.response;
        payload[0].value = data.totalRecords;
        payload[1].value = data.startCityRadius;
        payload[2].value = data.dropCityRadius;

        payload[3].value = data.lanes100;
        payload[4].value = data.color100;

        payload[5].value = data.lanes90;
        payload[6].value = data.color90;

        payload[7].value = data.lanes80;
        payload[8].value = data.color80;

        payload[9].value = data.lanes70;
        payload[10].value = data.color70;

        payload[11].value = data.lanes60;
        payload[12].value = data.color60;

        payload[13].value = data.lanes50;
        payload[14].value = data.color50;

        payload[15].value = data.lanes40;
        payload[16].value = data.color40;

        payload[17].value = data.lanes30;
        payload[18].value = data.color30;

        payload[19].value = data.lanes20;
        payload[20].value = data.color20;

        payload[21].value = data.lanes10;
        payload[22].value = data.color10;
        payload[23].value = data.numberOfDays;


        payload[24].value = data.totalRecordsGold;
        payload[25].value = data.startCityRadiusGold;
        payload[26].value = data.dropCityRadiusGold;

        console.log('NOOFDAYS');
        console.log(data.numberOfDays);


        console.log("updating payload");


        console.log(payload);
        UserService.updateConfig(payload, token).then(
            response => {
                setState({
                    content: response.data,
                    loading: false,
                    message: 'Success!'
                });
            },
            error => {
                if (error.response && error.response.status === 401) {
                }
            }
        );


        return false;
    }
    return (
        <div className="container-fluid AppFonts">
            <h5 className="bi-globe-asia-australia"> Global Configuration</h5>

            <div className="">
                {farelanesUser &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="">
                            <h5>Platinum</h5>
                            <div className="form-group col-sm-3">
                                <label>RFQ Number of records.</label>
                                <input name="firstName"
                                       type="text" {...register('totalRecords', registerOptions.totalRecords)}
                                       className="form-control AppFonts"/>
                            </div>

                            <div className="form-group col-sm-3">
                                <label>RFQ Start City or Zip Radius mi.</label>
                                <input name="milesGreaterThan"
                                       type="text" {...register('startCityRadius', registerOptions.startCityRadius)}
                                       className="form-control AppFonts"/>
                            </div>
                            <div className="form-group col-sm-3">
                                <label>RFQ End City or Zip Radius mi.</label>
                                <input name="lineHaulAmountGreaterThan"
                                       type="text" {...register('dropCityRadius', registerOptions.dropCityRadius)}
                                       className="form-control AppFonts"/>
                            </div>
                        </div>
<hr/>
                        <div className="">
                            <h5>Gold</h5>
                            <div className="form-group col-sm-3">
                                <label>RFQ Number of records.</label>
                                <input name="firstName"
                                       type="text" {...register('totalRecordsGold', registerOptions.totalRecordsGold)}
                                       className="form-control AppFonts"/>
                            </div>

                            <div className="form-group col-sm-3">
                                <label>RFQ Start City or Zip Radius mi.</label>
                                <input name="milesGreaterThan"
                                       type="text" {...register('startCityRadiusGold', registerOptions.startCityRadiusGold)}
                                       className="form-control AppFonts"/>
                            </div>
                            <div className="form-group col-sm-3">
                                <label>RFQ End City or Zip Radius mi.</label>
                                <input name="lineHaulAmountGreaterThan"
                                       type="text" {...register('dropCityRadiusGold', registerOptions.dropCityRadiusGold)}
                                       className="form-control AppFonts"/>
                            </div>
                        </div>
                        <h5 className="bi-globe-asia-australia"> Confidence Index</h5>


                        <div className="form-group col-sm-2">
                            <label>Number of days can be 15, 30, 60, and 120. Default is 120 </label>
                            <input name="numberOfDays"
                                   type="text" {...register('numberOfDays', registerOptions.numberOfDays)}
                                   className="form-control AppFonts"/>
                        </div>

                        <hr/>

                        <div className={"row"}>

                            <div className="form-group col-sm-1">
                                <label>&nbsp;</label>
                                <input type="text" value="100%" disabled={true}
                                       className="form-control AppFonts"></input>
                            </div>

                            <div className="form-group col-sm-2">
                                <label>Internal # lanes </label>
                                <input name="firstName"
                                       type="text" {...register('lanes100', registerOptions.lanes100)}
                                       className="form-control AppFonts"/>
                            </div>


                            <div className="form-group col-sm-1">
                                <label>Color Code</label>
                                <input name="lineHaulAmountGreaterThan"
                                       type="text" {...register('color100', registerOptions.color100)}
                                       className="form-control AppFonts"/>
                            </div>
                        </div>

                        <div className={"row"}>

                            <div className="form-group col-sm-1">
                                <label>&nbsp;</label>
                                <input type="text" value="90%" disabled={true}
                                       className="form-control AppFonts"></input>
                            </div>

                            <div className="form-group col-sm-2">
                                <label>Internal # lanes </label>
                                <input name="firstName"
                                       type="text" {...register('lanes90', registerOptions.lanes90)}
                                       className="form-control AppFonts"/>
                            </div>


                            <div className="form-group col-sm-1">
                                <label>Color Code</label>
                                <input name="lineHaulAmountGreaterThan"
                                       type="text" {...register('color90', registerOptions.color90)}
                                       className="form-control AppFonts"/>
                            </div>
                        </div>

                        <div className={"row"}>

                            <div className="form-group col-sm-1">
                                <label>&nbsp;</label>
                                <input type="text" value="80%" disabled={true}
                                       className="form-control AppFonts"></input>

                            </div>

                            <div className="form-group col-sm-2">
                                <label>Internal # lanes </label>
                                <input name="firstName"
                                       type="text" {...register('lanes80', registerOptions.lanes80)}
                                       className="form-control AppFonts"/>
                            </div>


                            <div className="form-group col-sm-1">
                                <label>Color Code</label>
                                <input name="lineHaulAmountGreaterThan"
                                       type="text" {...register('color80', registerOptions.color80)}
                                       className="form-control AppFonts"/>
                            </div>
                        </div>

                        <div className={"row"}>

                            <div className="form-group col-sm-1">
                                <label>&nbsp;</label>
                                <input type="text" value="70%" disabled={true}
                                       className="form-control AppFonts"></input>

                            </div>

                            <div className="form-group col-sm-2">
                                <label>Internal # lanes </label>
                                <input name="firstName"
                                       type="text" {...register('lanes70', registerOptions.lanes70)}
                                       className="form-control AppFonts"/>
                            </div>


                            <div className="form-group col-sm-1">
                                <label>Color Code</label>
                                <input name="lineHaulAmountGreaterThan"
                                       type="text" {...register('color70', registerOptions.color70)}
                                       className="form-control AppFonts"/>
                            </div>
                        </div>

                        <div className={"row"}>

                            <div className="form-group col-sm-1">
                                <label>&nbsp;</label>
                                <input type="text" value="60%" disabled={true}
                                       className="form-control AppFonts"></input>

                            </div>

                            <div className="form-group col-sm-2">
                                <label>Internal # lanes </label>
                                <input name="firstName"
                                       type="text" {...register('lanes60', registerOptions.lanes60)}
                                       className="form-control AppFonts"/>
                            </div>


                            <div className="form-group col-sm-1">
                                <label>Color Code</label>
                                <input name="lineHaulAmountGreaterThan"
                                       type="text" {...register('color60', registerOptions.color60)}
                                       className="form-control AppFonts"/>
                            </div>
                        </div>

                        <div className={"row"}>

                            <div className="form-group col-sm-1">
                                <label>&nbsp;</label>
                                <input type="text" value="50%" disabled={true}
                                       className="form-control AppFonts"></input>

                            </div>

                            <div className="form-group col-sm-2">
                                <label>Internal # lanes </label>
                                <input name="firstName"
                                       type="text" {...register('lanes50', registerOptions.lanes50)}
                                       className="form-control AppFonts"/>
                            </div>


                            <div className="form-group col-sm-1">
                                <label>Color Code</label>
                                <input name="lineHaulAmountGreaterThan"
                                       type="text" {...register('color50', registerOptions.color50)}
                                       className="form-control AppFonts"/>
                            </div>
                        </div>

                        <div className={"row"}>

                            <div className="form-group col-sm-1">
                                <label>&nbsp;</label>
                                <input type="text" value="40%" disabled={true}
                                       className="form-control AppFonts"></input>

                            </div>

                            <div className="form-group col-sm-2">
                                <label>Internal # lanes </label>
                                <input name="firstName"
                                       type="text" {...register('lanes40', registerOptions.lanes40)}
                                       className="form-control AppFonts"/>
                            </div>


                            <div className="form-group col-sm-1">
                                <label>Color Code</label>
                                <input name="lineHaulAmountGreaterThan"
                                       type="text" {...register('color40', registerOptions.color40)}
                                       className="form-control AppFonts"/>
                            </div>
                        </div>

                        <div className={"row"}>

                            <div className="form-group col-sm-1">
                                <label>&nbsp;</label>
                                <input type="text" value="30%" disabled={true}
                                       className="form-control AppFonts"></input>

                            </div>

                            <div className="form-group col-sm-2">
                                <label>Internal # lanes </label>
                                <input name="firstName"
                                       type="text" {...register('lanes30', registerOptions.lanes30)}
                                       className="form-control AppFonts"/>
                            </div>


                            <div className="form-group col-sm-1">
                                <label>Color Code</label>
                                <input name="lineHaulAmountGreaterThan"
                                       type="text" {...register('color30', registerOptions.color30)}
                                       className="form-control AppFonts"/>
                            </div>
                        </div>

                        <div className={"row"}>

                            <div className="form-group col-sm-1">
                                <label>&nbsp;</label>
                                <input type="text" value="20%" disabled={true}
                                       className="form-control AppFonts"></input>

                            </div>

                            <div className="form-group col-sm-2">
                                <label>Internal # lanes </label>
                                <input name="firstName"
                                       type="text" {...register('lanes20', registerOptions.lanes20)}
                                       className="form-control AppFonts"/>
                            </div>


                            <div className="form-group col-sm-1">
                                <label>Color Code</label>
                                <input name="lineHaulAmountGreaterThan"
                                       type="text" {...register('color20', registerOptions.color20)}
                                       className="form-control AppFonts"/>
                            </div>
                        </div>

                        <div className={"row"}>

                            <div className="form-group col-sm-1">
                                <label>&nbsp;</label>
                                <input type="text" value="10%" disabled={true}
                                       className="form-control AppFonts"></input>

                            </div>

                            <div className="form-group col-sm-2">
                                <label>Internal # lanes </label>
                                <input name="firstName"
                                       type="text" {...register('lanes10', registerOptions.lanes10)}
                                       className="form-control AppFonts"/>
                            </div>


                            <div className="form-group col-sm-1">
                                <label>Color Code</label>
                                <input name="lineHaulAmountGreaterThan"
                                       type="text" {...register('color10', registerOptions.color10)}
                                       className="form-control AppFonts"/>
                            </div>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="button__settings-save">

                                {state.loading && (
                                    <span className="spinner-border spinner-border-sm admin-sync-subit-spiiner"></span>
                                )}
                                &nbsp;&nbsp;<span className="">Save</span></button>
                        </div>
                        <br/><br/>

                        {state.content && (
                            <div className="green">
                                <div className="">
                                    <h5>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Saved !</h5>
                                </div>
                            </div>
                        )}
                        <br/><br/>

                    </form>
                }
                {!farelanesUser &&
                    <div className="text-center p-3">
                        <span className="spinner-border spinner-border-lg align-center"></span>
                    </div>
                }
            </div>
        </div>
    );
};

export default withAuthenticationRequired(GlobalConfig, {
    onRedirecting: () => <Loading/>,
});
