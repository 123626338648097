import React, {useEffect, useState} from "react";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import LaneService from "../services/LaneService";
import Select from "react-select";
import 'react-tooltip/dist/react-tooltip.css'


import AsyncSelect from "react-select/async";
import UserService from "../services/UserService";
import 'react-data-grid/lib/styles.css';
import {PageLoader} from "./page-loader";
import {Table} from "react-bootstrap";
import {customStyles, equipmentTypeStyles, radiusStyles} from "../pages/Constants";
import DataTable from "react-data-table-component";
import {confirmAlert} from "react-confirm-alert";

export const CitySearchAdvanced = () => {


    const [state, setState] = useState({
        messaged: '',
        apiMessage: undefined,
        error: null,
        subscription: false,
        subscriptionType: 'Default'
    });

    const equipmentOptions = [
        {value: 'Auto Carrier', label: 'Auto Carrier'},
        {value: 'Container', label: 'Container'},
        {value: 'Conestoga', label: 'Conestoga'},
        {value: 'Dry Van', label: 'Dry Van'},
        {value: 'Flatbed', label: 'Flatbed'},
        {value: 'Hopper Bottom', label: 'Hopper Bottom'},
        {value: 'Lowboy', label: 'Lowboy'},
        {value: 'Maxi', label: 'Maxi'},
        {value: 'Stretch Trailer', label: 'Stretch Trailer'},
        {value: 'Removable Gooseneck', label: 'Removable Gooseneck'},
        {value: 'Power Only', label: 'Power Only'},
        {value: 'Pneumatic', label: 'Pneumatic'},
        {value: 'Double Drop', label: 'Double Drop'},
        {value: 'Reefer', label: 'Reefer'},
        {value: 'Dump Trailer', label: 'Dump Trailer'},
        {value: 'Straight Box', label: 'Straight Box'},
        {value: 'Box Truck', label: 'Box Truck'},
        {value: 'Drop Deck', label: 'Drop Deck'},
        {value: 'Step Deck', label: 'Step Deck'},
        {value: 'Sprinter', label: 'Sprinter'},
        {value: 'Conveyor', label: 'Conveyor'},
        {value: 'Tanker', label: 'Tanker'},
        {value: 'All', label: 'All'},
        {value: 'Truck and Trailer', label: 'Truck & Trailer'},
    ]

    const dayRangeOptions = [
        {value: 3, label: '3 days'},
        {value: 7, label: '7 days'},
        {value: 15, label: '15 days'},
        {value: 30, label: '30 days'},
        {value: 45, label: '45 days'},
        {value: 60, label: '60 days'},
        {value: 90, label: '90 days'},
        {value: 120, label: '120 days'},
    ];

    const handleDayRangeValueChange = value => {
        setDayRangeValue(value);
    };

    const [dayRangeValue, setDayRangeValue] = useState({value: 30, label: '30 days'});

    const [apiFinished, setApiFinished] = React.useState(true);
    const {user, getAccessTokenSilently} = useAuth0();

    const callApi = async () => {

        const token = await getAccessTokenSilently();
        UserService.userSubscriptionStatusPair(token, user).then(
            response => {
                setState({
                    subscription: response.data.status,
                    subscriptionType: response.data.subscriptionType
                })
            },
            error => {
            }
        );
    };

    useEffect(() => {
        // declare the data fetching function
        callApi().then(r => console.log('Call() finished'));
    }, []);


    const financialNoDecimals = (x) => Number.parseFloat(x).toFixed(0);

    const columns = [
        {
            name: 'Equipment',
            selector: row => row.equipment,
            sortable: true,
            cell: row => <div className="top-routes-text">{row.equipment}</div>
        },
        {
            name: 'Pick City',
            selector: row => row.pick_city + ', ' + row.pick_state,
            sortable: true,
            cell: row => <div className="top-routes-text">{row.pick_city + ', ' + row.pick_state}</div>
        },

        {
            name: 'Drop City',
            selector: row => row.drop_city + ', ' + row.drop_state,
            sortable: true,
            cell: row => <div className="top-routes-text">{row.drop_city + ', ' + row.drop_state}</div>
        },

        {
            id: 'lane',
            name: 'Line Haul Rate',
            selector: row => '$' + financial(row.lane_rate),
            sortable: true,
            cell: row => <div className="top-routes-dollar-text">${financialNoDecimals(row.lane_rate)}</div>
        }, {
            id: 'pricePerMile',
            name: 'Price/Mile',
            selector: row => '$' + financialTwoPoints(row.lane_rate/row.miles),
            sortable: true,
            cell: row => <div className="top-routes-dollar-text">${financialTwoPoints(row.lane_rate/row.miles)}</div>
        },
    ];

    const handleEquipmentChange = (event) => {
        setEquipmentValue(event);
    };
    const [submitted, setSubmitted] = useState(false);
    const [laneStatistics, setLaneStatistics] = useState('default');
    const [inputPickCityValue, setPickCityValue] = useState('');
    const [inputDropCityValue, setDropCityValue] = useState('');
    const [selectedPickCityValue, setSelectedPickCityValue] = useState(null);
    const [selectedDropCityValue, setSelectedDropCityValue] = useState(null);
    const [selectedRateSearchHistoryValue, setSelectedRateSearchHistoryValue] = useState(null);
    const [searchHistoryValue, setSearchHistoryValue] = useState({value: 'Dry Van', label: 'Recent Search History'});
    const [equipmentValue, setEquipmentValue] = useState({value: 'All', label: 'All'});
    const [rateSearchHistoryValue, setRateSearchHistoryValue] = useState({
        pick_state: '', radius_miles: 'Dry Van',
        pick_city: 'Select user search history here'
    });
    const [radiusValue, setRadiusValue] = useState({value: -1, label: 'Select Miles'});
    const [pickCityRadiusValue, setPickCityRadiusValue] = useState({value: 200, label: '200 miles'});

    const pickCityRadiusOptions = [
        {value: 50, label: '50 miles'},
        {value: 100, label: '100 miles'},
        {value: 150, label: '150 miles'},
        {value: 200, label: '200 miles'},
        {value: 250, label: '250 miles'}
    ];

    const dropCityRadiusOptions = [
        {value: 50, label: '50 miles'},
        {value: 100, label: '100 miles'},
        {value: 150, label: '150 miles'},
        {value: 200, label: '200 miles'},
        {value: 250, label: '250 miles'}
    ]
    const handleRadiusChange = (event) => {
        setRadiusValue(event);
    };

    const handlePickCityRadiusChange = (event) => {
        setPickCityRadiusValue(event);
    };

    // handle input change event
    const handlePickCityInputChange = value => {
        setPickCityValue(value);
    };

    // handle selection
    const handlePickCityChange = value => {
        setSelectedPickCityValue(value);
    }

    const handleDropCityInputChange = value => {
        setDropCityValue(value);
    };

    // handle selection
    const handleDropCityChange = value => {
        setSelectedDropCityValue(value);
    }
    const [message, setMessage] = React.useState("");

    const financial = (x) => Number.parseFloat(x).toFixed(0);
    const financialTwoPoints = (x) => Number.parseFloat(x).toFixed(2);
    const handleFormSubmit = async value => {
        setApiFinished(false);
        const token = await getAccessTokenSilently();
        if (null == selectedPickCityValue) {
            setMessage('Pick City cannot be empty.');
            console.log('Returning');
            setApiFinished(true);
            confirmAlert({
                title: 'Start City cannot be empty.',
                message: message,
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {
                            console.log('New Item dash errors so allowing the user to start over.')
                        }
                    }
                ]
            });
            return;
        }
        try {
            const token = await getAccessTokenSilently();
            console.log('"*********************');
            console.log(selectedDropCityValue);
            console.log(selectedDropCityValue);
            console.log(radiusValue);

            console.log('"*********************');
            let selectedDropCityValueForAPI = {};
            if (null == selectedDropCityValue) {
                selectedDropCityValueForAPI.city = "EMPTY";
                selectedDropCityValueForAPI.stateName = "EMPTY";
            } else {
                selectedDropCityValueForAPI.city = selectedDropCityValue.city;
                selectedDropCityValueForAPI.stateName = selectedDropCityValue.stateName;
            }


            LaneService.getCityOptionsThree(user.sub, token, selectedPickCityValue.city, selectedPickCityValue.stateName, pickCityRadiusValue.value, equipmentValue.value,
                selectedDropCityValueForAPI.city, selectedDropCityValueForAPI.stateName, radiusValue.value,dayRangeValue.value).then(
                response => {
                    response.data.sort((a, b) => parseFloat(b.lane_rate) - parseFloat(a.lane_rate));
                    console.log(response.data);
                    console.log(response.data);
                    setState({
                        ...state,
                        apiMessage: response.data

                    });
                    setApiFinished(true);
                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
        setSubmitted(true);

    }

    // load city/zips using API call
    const loadOptions = async (inputValue) => {
        const token = await getAccessTokenSilently();
        return LaneService.getCityOptions(inputValue, token).then(
            response => {
                return response;
            },
            error => {
                console.log(error);
            }
        );
    };

    return (
        <>
            <div className="dashboard-text container-fluid">


                {/*{state.subscription &&*/}
                <Table>
                    <thead>
                    <tr>
                        <th>Equipment</th>
                        <th>Pick City</th>
                        <th>Radius</th>
                        <th>Days</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <Select
                                label="What equipment"
                                options={equipmentOptions}
                                value={equipmentValue}
                                styles={equipmentTypeStyles}
                                onChange={handleEquipmentChange}
                            />
                        </td>

                        <td>
                            <AsyncSelect
                                cacheOptions
                                styles={customStyles}
                                defaultOptions
                                value={selectedPickCityValue}
                                getOptionLabel={e => e.city + ', ' + e.stateName}
                                getOptionValue={e => e.city}
                                loadOptions={loadOptions}
                                onInputChange={handlePickCityInputChange}
                                onChange={handlePickCityChange}
                            />
                        </td>
                        <td>
                            <Select
                                label="What radius"
                                options={pickCityRadiusOptions}
                                styles={radiusStyles}
                                value={pickCityRadiusValue}
                                onChange={handlePickCityRadiusChange}
                            />
                        </td>
                        <td>
                            <Select
                                label="What days"
                                options={dayRangeOptions}
                                value={dayRangeValue}
                                styles={radiusStyles}
                                onChange={handleDayRangeValueChange}
                            />
                        </td>
                                <br/>
                        <td>
                            <button
                                className="add_lane_dashboard_button"
                                onClick={handleFormSubmit}
                                disabled={!apiFinished}
                            >
                                {!apiFinished && (
                                    <span className="spinner-grow"></span>
                                )}
                                <span>&nbsp;Query&nbsp;</span>

                            </button>

                        </td>
                                <br/>

                    </tr>
                    </tbody>

                    <div className="">
                        <small>&nbsp;{state.message}</small>
                    </div>




                    {/*NEw Cols*/}

                    <tbody>
                    <tr>
                        <th>To narrow your search, optionally enter a Drop City and a search radius.
                        </th>
                        <th>Drop City</th>
                        <th>Drop Radius</th>
                    </tr>
                    <tr>
                        <td>
           {/*<span className="opt-fields">Optional fields this row -> (Select Drop City and Radius)</span>*/}
                        </td>

                        <td>
                            <AsyncSelect
                                cacheOptions
                                styles={customStyles}
                                defaultOptions
                                value={selectedDropCityValue}
                                getOptionLabel={e => e.city + ', ' + e.stateName}
                                getOptionValue={e => e.city}
                                loadOptions={loadOptions}
                                onInputChange={handleDropCityInputChange}
                                onChange={handleDropCityChange}
                            />
                        </td>

                        <td>
                            <Select
                                label="Drop Radius radius"
                                options={dropCityRadiusOptions}
                                styles={radiusStyles}
                                value={radiusValue}
                                onChange={handleRadiusChange}
                            />
                        </td>



                    </tr>
                    </tbody>

                    <div className="">
                        <small>&nbsp;{state.message}</small>
                    </div>
                </Table>
                {/*}*/}

                {!apiFinished && (<div className="text-center p-3">
                    <PageLoader></PageLoader>
                </div>)}

                {apiFinished && (<div className="text-center p-3">
                    <div className="container-fluid">
                        <header className="top-routes-text ">

                            <DataTable
                                // dense={true}
                                customStyles={customStyles}
                                striped={true}
                                columns={columns}
                                data={state.apiMessage}
                                // defaultSortFieldId="lane"
                                defaultSortAsc={false}
                                direction="auto"
                                fixedHeaderScrollHeight="300px"
                                pagination
                                responsive
                                subHeaderAlign="right"
                                subHeaderWrap
                            />
                        </header>

                    </div>

                </div>)}


            </div>
            <br/><br/>
        </>
    );
};

export default withAuthenticationRequired(CitySearchAdvanced, {
    onRedirecting: () => <PageLoader/>,
});
