import React, {useEffect, useState} from "react";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import UserService from "../services/UserService";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {formatValue} from "react-currency-input-field";
import RatesMap from "./RatesMap";
import LazyLoad from "react-lazy-load";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {abbrRegion} from "../pages/Constants";
import 'react-tooltip/dist/react-tooltip.css'


export const LaneData = () => {

    const [state, setState] = useState({
        showResult: false,
        apiMessage: undefined,
        error: null,
        subscription: false,
        subscriptionType: 'Default',
        enable_assesorials: null
    });
    const {
        getAccessTokenSilently,
        user
    } = useAuth0();

    const callApi = async () => {
        const token = await getAccessTokenSilently();
        UserService.userSubscriptionStatusPair(token, user).then(
            firstResponse => {

                state.subscriptionType = firstResponse.data.subscriptionType;
                setState({
                    ...state,
                    subscription: firstResponse.data.status,
                    subscriptionType: firstResponse.data.subscriptionType,

                })

                UserService.getLaneData(token, user).then(
                    response => {
                        setState({
                            ...state,
                            showResult: true,
                            apiMessage: response.data,
                            enable_assesorials: firstResponse && firstResponse.data && firstResponse.data.user && firstResponse.data.user.enable_assesorials
                        });
                    }
                );
            },
            error => {
                console.log('Error', error);
            }
        );

    };

    useEffect(() => {
        // declare the data fetching function
        callApi().then(r => {});
    }, []);
    return (
        <>

            <LazyLoad height={290}>
                <div>
                    {!state.showResult && (<div>
                        <div className="container-fluid">
                            <SkeletonTheme baseColor="ghostwhite" highlightColor="#ffffff">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Skeleton duration={4} count={16}/>
                                    </div>
                                    <div className="col-sm-4">
                                        <Skeleton duration={4} count={16}/>
                                    </div>
                                    <div className="col-sm-4">
                                        <Skeleton duration={4} count={16}/>
                                    </div>
                                </div>

                            </SkeletonTheme>
                        </div>


                    </div>)}
                    <div className={""}>
                        <div className="row">
                            {state.showResult && state.apiMessage.map(home => <div key={home.pickCityLat} className="col-sm-4">{
                                <div>
                                    <div className="">
                                        <div className={
                                            home != undefined && home.queryCurrentStatistic != undefined && home.queryCurrentStatistic.costPerMile.toString() == '0'
                                                ? "card  special-card"
                                                : "card empty-background"
                                        }
                                        >
                                            {state.enable_assesorials &&
                                            <div className="card-body ">

                                                {home != undefined && home.queryCurrentStatistic != undefined && (


                                                    <a data-tooltip-id="fifteenDaysAverage" data-tooltip-html=
                                                        {
                                                            'No.of.stops - low ' + home.queryCurrentStatistic.lowNumberOfStops + ' high ' + home.queryCurrentStatistic.highNumberOFStops + '<br/>' +
                                                            'Lead time - low' + home.queryCurrentStatistic.lowLeadTime + ' high ' + home.queryCurrentStatistic.highLeadTime + '<br/>' +
                                                            'Fuel surcharge - low' + home.queryCurrentStatistic.lowFuelSurcharge + ' high ' + home.queryCurrentStatistic.highFuelSurcharge + '<br/>' +
                                                            'Lumper - low' + home.queryCurrentStatistic.lowLumper + ' high ' + home.queryCurrentStatistic.highLumper + '<br/>' +
                                                            'Pallet charge - low' + home.queryCurrentStatistic.lowPalletCharge + ' high ' + home.queryCurrentStatistic.highPalletCharge + '<br/>' +
                                                            'Weight ticket - low' + home.queryCurrentStatistic.lowWeightTicket + ' high ' + home.queryCurrentStatistic.highWeightTicket + '<br/>' +
                                                            'Interchange - low' + home.queryCurrentStatistic.lowInterchange + ' high ' + home.queryCurrentStatistic.highInterchange + '<br/>' +
                                                            'Container fee - low' + home.queryCurrentStatistic.lowContainerFee + ' high ' + home.queryCurrentStatistic.highContainerFee + '<br/>' +
                                                            'Truck order - low' + home.queryCurrentStatistic.lowTruckOrder + ' high ' + home.queryCurrentStatistic.highTruckOrder + '<br/>' +
                                                            'Unloading fees - low' + home.queryCurrentStatistic.lowUnloadingFees + ' high ' + home.queryCurrentStatistic.highUnloadingFees + '<br/>' +
                                                            'Detention - low' + home.queryCurrentStatistic.lowDetention + ' high ' + home.queryCurrentStatistic.highDetention + '<br/>'
                                                        }>
                                                        <div
                                                            className="green-bg"><>{home.startCity} {abbrRegion(home.startState)}  &nbsp;
                                                            <span><i className="bi bi-arrow-right"></i></span>
                                                            &nbsp;&nbsp;{home.endCity}  {abbrRegion(home.endState)}</>
                                                        </div>
                                                        <div className="row rates_border">
                                                            <div className="col">
                                                                <RatesMap latlng={{
                                                                    isMobile: true,
                                                                    pickCityLat: home.pickCityLat,
                                                                    pickCityLng: home.pickCityLng,
                                                                    dropCityLat: home.dropCityLat,
                                                                    dropCityLng: home.dropCityLng,
                                                                }}></RatesMap>
                                                            </div>
                                                        </div>
                                                    </a>)
                                                }
                                                {home != undefined && home.queryCurrentStatistic != undefined &&
                                                    <div>
                                                        <div>
                                                            {(state.subscriptionType == 'Gold' || state.subscriptionType == 'Platinum') &&
                                                                <ReactTooltip className="tooltipss" className='custom-color' place='left-start' border
                                                                              textColor='#5F4B8BFF' backgroundColor='#E69A8DFF' borderColor='darkgreen' arrowColor='red' id="fifteenDaysAverage" multiline={true}/>


                                                            }

                                                        </div>
                                                        <div className="gray-bg">
                                                <span>{home.eqipment}&nbsp;<i
                                                    className="bi bi-text-left"></i> {formatValue({
                                                    value: home.queryCurrentStatistic.costPerMile.toString(),
                                                    intlConfig: {locale: 'en-US', currency: 'USD'},
                                                    decimalScale: 2,
                                                    suffix: '/mile'
                                                })} &nbsp;<i className="bi bi-text-left"></i>&nbsp;

                                                    {formatValue({
                                                        value: home.queryCurrentStatistic.averageLoadRate.toString(),
                                                        intlConfig: {locale: 'en-US', currency: 'USD'},
                                                        decimalScale: 0,
                                                        suffix: '/load'
                                                    })}</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            }


                                            {!state.enable_assesorials &&
                                                <div className="card-body ">

                                                    {home != undefined && home.queryCurrentStatistic != undefined && (


                                 <div>
                                                            <div
                                                                className="green-bg"><>{home.startCity} {abbrRegion(home.startState)}  &nbsp;
                                                                <span><i className="bi bi-arrow-right"></i></span>
                                                                &nbsp;&nbsp;{home.endCity}  {abbrRegion(home.endState)}</>
                                                            </div>
                                                            <div className="row rates_border">
                                                                <div className="col">
                                                                    <RatesMap latlng={{
                                                                        isMobile: true,
                                                                        pickCityLat: home.pickCityLat,
                                                                        pickCityLng: home.pickCityLng,
                                                                        dropCityLat: home.dropCityLat,
                                                                        dropCityLng: home.dropCityLng,
                                                                    }}></RatesMap>
                                                                </div>
                                                            </div>
                                 </div>)
                                                    }
                                                    {home != undefined && home.queryCurrentStatistic != undefined &&
                                                        <div>
                                                            <div>
                                                                {state.subscriptionType == 'Gold' &&
                                                                    <ReactTooltip id="fifteenDaysAverage" multiline={true}/>
                                                                }

                                                            </div>
                                                            <div className="gray-bg">
                                                <span>{home.eqipment}&nbsp;<i
                                                    className="bi bi-text-left"></i> {formatValue({
                                                    value: home.queryCurrentStatistic.costPerMile.toString(),
                                                    intlConfig: {locale: 'en-US', currency: 'USD'},
                                                    decimalScale: 2,
                                                    suffix: '/mile'
                                                })} &nbsp;<i className="bi bi-text-left"></i>&nbsp;

                                                    {formatValue({
                                                        value: home.queryCurrentStatistic.averageLoadRate.toString(),
                                                        intlConfig: {locale: 'en-US', currency: 'USD'},
                                                        decimalScale: 0,
                                                        suffix: '/load'
                                                    })}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            } </div>)}
                        </div>
                    </div>
                </div>
            </LazyLoad>
        </>
    );
};

export default withAuthenticationRequired(LaneData, {
    onRedirecting: () => <Loading/>,
});
