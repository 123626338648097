import React, {useEffect, useState} from "react";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import UserService from "../services/UserService";
import Progress from "./Progress";

import { Parser } from 'html-to-react';
import {PageLoader} from "./page-loader";
import Marquee from "react-fast-marquee";

export const TickerMobileComponent = (props) => {

    const [state, setState] = useState({
        showResult: false,
        apiMessage: 'Tickers are loading ....',
        error: null,
    });

    const {
        getAccessTokenSilently,
        user
    } = useAuth0();

    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            UserService.getTicker(token, user, props.ticker.id).then(
                response => {
                    setState({
                        ...state,
                        showResult: true,
                        apiMessage: response.data,
                    });
                }
            );
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }
    };

    useEffect(() => {

        // declare the data fetching function
        callApi();

    }, []);


    return (
        <div className="">
            {!state.showResult && (<div className="text-center p-3">
                {/*<PageLoader></PageLoader>*/}
            </div>)}
            {state.showResult && (<div className="">
                    <fieldset className="row one-space-low-mobile">
                        {/*<legend className="v2-ticker-legend">{state.apiMessage.tickerName}</legend>*/}

                        <Marquee pauseOnClick={true} pauseOnHover={true} speed={12}>
                            <div className="">
                                <div className="row">

                                    <div className="">
                                        <div className="">
                                            <div className="new-ticker-mobile new-ticker-overlay">
                                                {Parser().parse(state.apiMessage.feed)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Marquee>
                    </fieldset>
                </div>
            )}

        </div>
    );
};

export default withAuthenticationRequired(TickerMobileComponent, {
    onRedirecting: () => <Loading/>,
});
