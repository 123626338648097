import React, {useEffect, useRef, useState} from "react";
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import Loading from "../components/Loading";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";

import * as jose from 'jose'
import {
    MONGO_CHARTS_AUTH_SIGN_KEY,
    MONGO_CHARTS_AUTH_SIGN_KEY_ALGORITHM,
    MONGO_CHARTS_AUTH_SIGN_KEY_AUDIENCE,
    MONGO_CHARTS_AUTH_SIGN_KEY_EXPIRY_TIME,
    MONGO_CHARTS_AUTH_SIGN_KEY_ISSUER,
    MONGO_CHARTS_AUTH_SIGN_KEY_USER, MONGO_CHARTS_BASE_URL
} from "../common/ApiUrl";
export const MongoChart = ({filter, chartId, height, width}) => {


    const chartDiv = useRef(null);
    const [rendered, setRendered] = useState(false);

    const secret = new TextEncoder().encode(
        MONGO_CHARTS_AUTH_SIGN_KEY,
    );

    const alg = MONGO_CHARTS_AUTH_SIGN_KEY_ALGORITHM;


    const jwt =  new jose.SignJWT({ username: MONGO_CHARTS_AUTH_SIGN_KEY_USER})
        .setProtectedHeader({ alg })
        .setIssuedAt()
        .setIssuer(MONGO_CHARTS_AUTH_SIGN_KEY_ISSUER)
        .setAudience(MONGO_CHARTS_AUTH_SIGN_KEY_AUDIENCE)
        .setExpirationTime(MONGO_CHARTS_AUTH_SIGN_KEY_EXPIRY_TIME)
        .sign(secret)

    const sdk = new ChartsEmbedSDK({
        baseUrl: MONGO_CHARTS_BASE_URL,
        getUserToken: async function() {
            return jwt
        }
    });

    const [chart] = useState(sdk.createChart({chartId: chartId, height: height, width: width, showAttribution: false}));

    useEffect(() => {
        chart.render(chartDiv.current).then(() => setRendered(true)).catch(err => console.log("Error during Charts rendering.", err));
    }, [chart]);

    return <div className="chart" ref={chartDiv}/>;
};

export default withAuthenticationRequired(MongoChart, {
    onRedirecting: () => <Loading/>,
});
