import React, {useEffect, useState} from "react";
import {NavLink as RouterNavLink, NavLink} from "react-router-dom";
import FarelanesLogo from "../../../assets/images/LOGO.png"
import FarelanesCOPILOTLogo from "../../../assets/images/FL-CO-PILOT-logo.png"
import FarelanesSilverLogo from "../../../assets/images/FL-silver-edition.png"
import FarelanesGoldLogo from "../../../assets/images/FL-gpld-edition.png"
import FarelanesPlatinumLogo from "../../../assets/images/FL-platinum-edition.png"
import {useAuth0} from "@auth0/auth0-react";
import UserService from "../../../services/UserService";
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    NavbarBrand,
    NavItem,
    UncontrolledDropdown
} from "reactstrap";
import Ticker from "../../Ticker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const NavBarBrand = () => {

    const [subscriptionPlan, setsubscriptionPlan] = useState("Default");

    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        logoutWithRedirect,
        logout,
    } = useAuth0();


    useEffect(() => {
        // 👇️ This only runs once
        // 👇️ fetch data from remote API
        async function getUsers() {
            try {
                const token = await getAccessTokenSilently();
                UserService.mySubscription(token).then(
                    response => {
                        setsubscriptionPlan(response.data)
                    },
                    error => {
                        console.log('API timeout or session error; Kill session and redirect to login' + error);
                        loginWithRedirect({appState: {returnTo: '/'}});
                    }
                );
            } catch (err) {
                console.log(err);
            }
        }

        getUsers();
    }, []); // 👈️ Empty dependencies array


    return (
        <div>
            <div>

                {subscriptionPlan == 'CoPilot' && (
                    <NavLink to="/dashboard">
                        <img
                            className="v2-brand-logo"
                            src={FarelanesCOPILOTLogo}
                            alt="Farelanes logo"
                            width="250"
                            height="75"
                        />
                    </NavLink>
                )}
                {subscriptionPlan == 'Silver' && (
                    <NavLink to="/dashboard">
                        <img
                            className="v2-brand-logo"
                            src={FarelanesSilverLogo}
                            alt="Farelanes logo"
                            width="250"
                            height="75"
                        />
                    </NavLink>
                )}

                {subscriptionPlan == 'Platinum' && (
                    <NavLink to="/dashboard">
                        <img
                            className="v2-brand-logo"
                            src={FarelanesPlatinumLogo}
                            alt="Farelanes logo"
                            width="250"
                            height="75"
                        />
                    </NavLink>
                )}

                {subscriptionPlan == 'Gold' && (
                    <NavLink to="/dashboard">
                        <img
                            className="v2-brand-logo"
                            src={FarelanesGoldLogo}
                            alt="Farelanes logo"
                            width="250"
                            height="75"
                        />
                    </NavLink>
                )}

                {subscriptionPlan == 'Default' && (
                    <NavLink to="/dashboard">
                        <img
                            className="v2-brand-logo"
                            src={FarelanesLogo}
                            alt="Farelanes logo"
                            width="250"
                            height="75"
                        />
                    </NavLink>
                )}
            </div>

        </div>
    );
};
