export const API_BASE_URL = "https://api.myfarelanes.com/api/";

export const COPILOT_MONTHLY_API_KEY = "price_1QSWslLgudBKYy4G87lF1rXE";
export const COPILOT_YEARLY_API_KEY = "price_1QSWsJLgudBKYy4GuVRuDDQf";

export const SILVER_MONTHLY_API_KEY = "price_1On3lTLgudBKYy4GSrebZfew";
export const SILVER_YEARLY_API_KEY = "price_1On3lNLgudBKYy4GBq29o7V9";



export const GOLD_MONTHLY_API_KEY = "price_1On3l8LgudBKYy4GIag0TNno";
export const GOLD_YEARLY_API_KEY = "price_1On3lILgudBKYy4GlvZFy9mB";


export const PLATINUM_MONTHLY_API_KEY = "price_1PwJb0LgudBKYy4G24KCv2H2";
export const PLATINUM_YEARLY_API_KEY = "price_1PwJbALgudBKYy4Gwn8M9xfn";


export const STRIPE_KEY = "pk_live_51NXRnlLgudBKYy4GBVhkCeRQtdDwtrhOqCL51PDLdtVlqoCoLlaDA0Fc18cBb2tnYI7vHW4TaGpZ9CGTWbxZzmCk00INbqOUxq";

export const MONGO_CHARTS_AUTH_SIGN_KEY = "kjdflkdjflkdf87dkfnj8787jsdhs878";
export const MONGO_CHARTS_AUTH_SIGN_KEY_ALGORITHM = "HS256";
export const MONGO_CHARTS_AUTH_SIGN_KEY_USER = "FarelanesWeb";
export const MONGO_CHARTS_AUTH_SIGN_KEY_ISSUER = "FarelanesWeb";
export const MONGO_CHARTS_AUTH_SIGN_KEY_AUDIENCE = "FarelanesWeb";
export const MONGO_CHARTS_AUTH_SIGN_KEY_EXPIRY_TIME = "2h";
export const MONGO_CHARTS_BASE_URL = "https://charts.mongodb.com/charts-project-0-qpmdy";
